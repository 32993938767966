/* eslint-disable react-hooks/exhaustive-deps */
/* EXTERNAL */
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Link, Stack, Typography } from "@mui/material";
import { FieldValues, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate } from "react-router-dom";

/* LOCAL */
import { loginSchema } from "../../../utils/YupSchemas/User";
import { stringifyErrorMessage } from "../../../utils/ConversionMethods";
import { IUserLogin } from "../../../interfaces/User";
import { useAuth } from "../../../routers/useAuth";
import GenericButton from "../../Generics/buttons/GenericButton";
import Header from "../Header/Header";
import { frontOfficeRoutes } from "../../../resources/Routes";
import GenericTextField from "../../Generics/GenericTextField/GenericTextField";
import { StatusCode } from "../../../resources/StatusCode";
import ChangePasswordRequest from "./ChangePasswordRequest";
import LegalNoticeUpdateDialog from "./LegalNoticeUpdateDialog";
import { IOrganization } from "../../../interfaces/Organization";
import { emptyOrganization } from "../../../resources/AppConstants";
import OrganizationService from "../../../services/OrganizationService";

export default function Login() {
  const auth = useAuth();
  const navigate = useNavigate();
  const [changePasswordRequest, setChangePasswordRequest] = useState<boolean>(false);
  const [openLegalNoticeUpdate, setOpenLegalNoticeUpdate] = useState<boolean>(false);
  const [uniqueOrganization, setUniqueOrganization] = useState<IOrganization>(emptyOrganization);
  const [userData, setUserData] = useState<IUserLogin>({ email: "", password: "" });
  const [loading, setLoading] = useState<boolean>(true);

  const fetchUniqueOrganization = async () => {
    setLoading(true);
    const uniqueOrganizationRes = await OrganizationService().getUniqueOrganization();
    setUniqueOrganization(uniqueOrganizationRes.data);
    setLoading(false);
  };

  useEffect(() => {
    fetchUniqueOrganization();
  }, []);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isValid },
  } = useForm({
    resolver: yupResolver(loginSchema),
    mode: "onTouched",
  });

  const handleLogin = async (loginUserData: IUserLogin) => {
    const res = await auth.login(loginUserData);
    if (res.status === StatusCode.OK) {
      navigate(frontOfficeRoutes.home);
      reset();
    } else if ((res as any).response.data.message === "User have not accepted last CGU version") {
      setUserData(loginUserData);
      setOpenLegalNoticeUpdate(true);
    } else {
      toast.error("Email ou mot de passe incorrect.");
    }
  };

  const onSubmit = async (data: FieldValues) => {
    const loginUserData: IUserLogin = {
      email: data.email,
      password: data.password,
    };
    await handleLogin(loginUserData);
  };

  const onCGUAccepted = async () => {
    const loginUserData: IUserLogin = {
      email: userData.email,
      password: userData.password,
      acceptedLastCGU: true,
    };
    await handleLogin(loginUserData);
  };

  const renderLoginPage = () => (
    <Stack alignItems="center" spacing={10} width="30%" onSubmit={handleSubmit(onSubmit)} component="form">
      <Typography variant="h4">Accédez à votre espace</Typography>
      <Stack alignItems="center" spacing={2} width="100%">
        <GenericTextField
          defaultValue=""
          required
          id="email"
          label="Email"
          error={!!errors.email}
          helperText={stringifyErrorMessage(errors.email)}
          register={register("email")}
        />
        <GenericTextField
          defaultValue=""
          required
          type="password"
          id="password"
          label="Mot de passe"
          error={!!errors.password}
          helperText={stringifyErrorMessage(errors.password)}
          register={register("password")}
        />
      </Stack>
      <GenericButton text="Se connecter" disabled={!isValid} login />
    </Stack>
  );
  return (
    <Stack justifyContent="center" alignItems="center" spacing={3}>
      {!loading && (
        <>
          <Header
            title="Connexion à votre compte monduerp.fr"
            subtitle={`Le site ${window.location.hostname} est destiné aux adhérents de ${uniqueOrganization.organizationName}.`}
            marginBottom={5}
            subtitleChildren
          >
            <Typography sx={{ mt: 2 }}>
              {`Si vous n’êtes pas adhérent à ${uniqueOrganization.organizationName}, vous pouvez réaliser votre DUERP en toute autonomie grâce à
            l’outil OIRA disponible `}
              <a
                style={{ fontStyle: "italic" }}
                href="https://www.inrs.fr/metiers/oira-outil-tpe.html"
                target="_blank"
                rel="noopener noreferrer"
              >
                ici
              </a>
              , ou demander un accompagnement à votre service de prévention et de santé au travail.
            </Typography>
          </Header>
          {changePasswordRequest ? <ChangePasswordRequest /> : renderLoginPage()}
          <Link component="button" variant="body1" onClick={() => setChangePasswordRequest(!changePasswordRequest)}>
            {changePasswordRequest ? "Revenir à l'écran de connexion" : "Générer un nouveau mot de passe"}
          </Link>
          <LegalNoticeUpdateDialog open={openLegalNoticeUpdate} onAccept={onCGUAccepted} />
        </>
      )}
    </Stack>
  );
}
