/* eslint-disable no-nested-ternary */
import {
  Box,
  Step,
  StepLabel,
  StepButton,
  StepContent,
  Stepper,
  Typography,
  Stack,
  Tooltip,
  StepIconProps,
  SvgIcon,
} from "@mui/material";
import { CheckCircle, EditRounded, HelpRounded, InfoRounded } from "@mui/icons-material";
import { Dispatch, Fragment, SetStateAction } from "react";
import colors from "../../../../../resources/cssConstant";
import { INavigationElement, IPosition } from "../../../../../utils/DuerpTransformation";
import { IDuerpQuestion, IDuerpSection } from "../../../../../interfaces/DuerpForm";
import StepperCircularProgress from "../../../../Generics/CircularProgress/StepperCircularProgress";

interface DuerpStepperProps {
  flatDuerp: INavigationElement[];
  flatIndex: number;
  progressValue: number;
  setFlatIndex: Dispatch<SetStateAction<number>>;
  completed: { [k: number]: boolean };
}

export default function DuerpStepper({
  flatDuerp,
  flatIndex,
  setFlatIndex,
  completed,
  progressValue,
}: Readonly<DuerpStepperProps>) {
  const isQuestion = (listElement: IDuerpQuestion | IDuerpSection) => "currentMeasures" in listElement;
  const specialSectionIndexes = [0, 1, flatDuerp.length - 2, flatDuerp.length - 1];

  const renderIcon = (props: StepIconProps, navigationIndex: number, position?: IPosition) => {
    let displayCompletedOrInfo = <HelpRounded />;
    if (specialSectionIndexes.includes(navigationIndex)) {
      displayCompletedOrInfo = <InfoRounded />;
    }
    const localisationCondition = position
      ? flatDuerp[flatIndex].position.currentSectionIndex === position.currentSectionIndex
      : flatIndex === navigationIndex;

    return (
      <SvgIcon>
        {localisationCondition ? <EditRounded /> : renderInternalIcon(navigationIndex, displayCompletedOrInfo)}
      </SvgIcon>
    );
  };

  const renderInternalIcon = (navigationIndex, displayCompletedOrInfo) => {
    return completed[navigationIndex] ? <CheckCircle /> : displayCompletedOrInfo;
  };
  const renderColor = (navigationIndex: number, position?: IPosition) => {
    const localisationCondition = position
      ? flatDuerp[flatIndex].position.currentSectionIndex === position.currentSectionIndex
      : flatIndex === navigationIndex;
    return localisationCondition ? colors.mainOrange : renderInternalColor(navigationIndex);
  };

  const renderInternalColor = (navigationIndex) => {
    return completed[navigationIndex] ? colors.stepperActionValidate : colors.white;
  };

  const isActive = (navigationIndex: number) => flatIndex === navigationIndex;

  const expandSection = (position: IPosition) =>
    flatDuerp[flatIndex].position.currentSectionIndex === position.currentSectionIndex &&
    (position.currentSubSectionIndex > -1
      ? flatDuerp[flatIndex].position.currentSubSectionIndex === position.currentSubSectionIndex
      : true);

  const renderQuestionStepper = (navigationIndex: number, element: IDuerpSection) => (
    <Stepper nonLinear orientation="vertical" activeStep={flatIndex} sx={{ color: colors.white }} connector={<Box />}>
      {element.questions?.map((question: IDuerpQuestion, index: number) => (
        <Step key={question.uuid}>
          <StepButton onClick={() => setFlatIndex(navigationIndex + index + 1)}>
            <StepLabel
              StepIconComponent={(props) => renderIcon(props, navigationIndex + index + 1)}
              sx={{ color: renderColor(navigationIndex + index + 1) }}
            >
              <Tooltip arrow title={question.title} placement="right">
                <Typography variant="body1" sx={{ color: colors.white }}>
                  Question {index + 1}
                </Typography>
              </Tooltip>
            </StepLabel>
          </StepButton>
        </Step>
      ))}
    </Stepper>
  );

  return (
    <Stack sx={{ height: "100%", backgroundColor: colors.primary, p: 1 }} spacing={2}>
      <Stack direction="row" alignItems="center" spacing={1}>
        <StepperCircularProgress progressValue={progressValue} />
        <Typography variant="body2" sx={{ color: colors.white }}>
          Étapes du questionnaire
        </Typography>
      </Stack>
      <Stepper nonLinear orientation="vertical" activeStep={flatIndex} sx={{ color: colors.white }} connector={<Box />}>
        {flatDuerp.map((navigationElement, navigationIndex) => {
          const { element, position } = navigationElement;
          if (isQuestion(element)) {
            return <Fragment key={element.uuid} />;
          }
          return (
            <Step
              active={isActive(navigationIndex)}
              completed={completed[navigationIndex]}
              key={element.uuid}
              expanded={expandSection(position)}
            >
              <StepButton onClick={() => setFlatIndex(navigationIndex)}>
                <StepLabel
                  StepIconComponent={(props) => renderIcon(props, navigationIndex)}
                  sx={{ color: renderColor(navigationIndex, position) }}
                >
                  <Typography variant="body1" sx={{ color: colors.white }}>
                    {position.currentSectionIndex > -1 && position.currentSectionIndex + 1}
                    {position.currentSubSectionIndex > -1 && `.${position.currentSubSectionIndex + 1}`}
                    {position.currentSectionIndex > -1 && ` - `}
                    {element.title}
                  </Typography>
                </StepLabel>
              </StepButton>
              {(element as IDuerpSection).questions && (
                <StepContent>{renderQuestionStepper(navigationIndex, element as IDuerpSection)}</StepContent>
              )}
            </Step>
          );
        })}
      </Stepper>
    </Stack>
  );
}
