import { Box, Button, FormControl, InputAdornment, InputLabel, MenuItem, Select, Stack } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { faUpload } from "@fortawesome/free-solid-svg-icons";
import { DateTime } from "luxon";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Visibility, CheckCircle, Cancel } from "@mui/icons-material";
import FormService from "../../../services/FormService";
import { emptyOira } from "../../../resources/AppConstants";
import { IOiraForm, IOiraImportError } from "../../../interfaces/OiraForm";
import SkeletonOira from "./SkeletonOira";
import Header from "../../Commons/Header/Header";
import OiraSection from "./containers/OiraSection/OiraSection";
import colors from "../../../resources/cssConstant";
import { StatusCode } from "../../../resources/StatusCode";

export default function Oira() {
  const location = useLocation();
  const [loading, setLoading] = useState<boolean>(false);
  const [oira, setOira] = useState<IOiraForm>(emptyOira);
  const [oiraGroup, setOiraGroup] = useState<IOiraForm[]>([]);
  const { formUuid, groupUuid } = location.state;
  const unpublishedOira = "archived_element";
  const [publishedVersion, setPublishedVersion] = useState<string>(unpublishedOira);
  const hiddenFileInput = useRef(null);

  const fetchOiraGroup = async (uuid: string) => {
    const res = await FormService().getOiraGroup(uuid);
    if (res.data) {
      setOiraGroup(res.data);
    } else {
      setOiraGroup([]);
    }
  };

  const fetchOira = async () => {
    const res = await FormService().getOiraById(formUuid);
    setOira(res.data ?? emptyOira);
    await fetchOiraGroup(groupUuid);
  };

  useEffect(() => {
    setLoading(true);
    fetchOira();
    const oiraFound = oiraGroup.find((oiraInGroup) => oiraInGroup.published);
    if (oiraFound) setPublishedVersion(oiraFound.uuid);
    setLoading(false);
  }, []);

  useEffect(() => {
    const oiraFound = oiraGroup.find((oiraInGroup) => oiraInGroup.published);
    setPublishedVersion(oiraFound?.uuid ?? unpublishedOira);
  }, [oiraGroup]);

  const importOiraForm = async (event) => {
    const fileUploaded = event.target.files[0];
    const response = await FormService().importOiraFormInGroup(fileUploaded, oira.groupUuid);
    if (response.status === StatusCode.CREATED) {
      toast.success(`Vous avez importé une nouvelle version de l'OiRA.`);
      await fetchOira();
    } else {
      const errorDTO: IOiraImportError = response.data as unknown as IOiraImportError;
      toast.error(
        `Impossible d'importer cet OiRA : la cellule ligne ${errorDTO.row} colonne "${errorDTO.headerName}" est vide.`,
      );
    }
  };

  const archiveOira = async () => {
    const res = await FormService().unpublishOira(oira.groupUuid);
    if (res) {
      await fetchOiraGroup(oira.groupUuid);
      toast.success(`Vous avez bien archivé l'OiRA ${oira.title}. Aucune version n'est publiée.`);
    } else {
      toast.error("Impossible d'archiver cet OiRA.");
    }
  };

  const changePublishedVersion = async (uuid: string) => {
    const res = await FormService().publishOiraVersion(uuid);
    if (res.status === StatusCode.OK) {
      await fetchOiraGroup(oira.groupUuid);
      toast.success("Vous avez bien modifié la version publiée.");
    } else {
      toast.error("Impossible de publier l'OiRA sélectionné.");
    }
  };

  const changePublicationStatus = async (uuid: string) => {
    if (uuid !== unpublishedOira) {
      await changePublishedVersion(uuid);
    } else {
      await archiveOira();
    }
  };

  const changeDisplayedVersion = async (uuid: string) => {
    const res = await FormService().getOiraById(uuid);
    if (res.data) {
      setOira(res.data);
    } else {
      toast.error("Impossible de récupérer l'OiRA sélectionné.");
    }
  };

  const renderSelection = (title: string, publicationSelect: boolean, handleChange: (value) => void) => {
    const publicationIcon = publishedVersion === unpublishedOira ? <Cancel /> : <CheckCircle />;
    return (
      <FormControl fullWidth>
        <InputLabel id="simple-select-label">{title}</InputLabel>
        <Select
          labelId="simple-select-label"
          id="simple-select"
          value={publicationSelect ? publishedVersion : oira.uuid}
          label={title}
          onChange={(event) => handleChange(event.target.value)}
          sx={{ backgroundColor: colors.white }}
          startAdornment={
            <InputAdornment
              position="start"
              sx={{
                color: publicationSelect && publishedVersion === unpublishedOira ? colors.deleteIcon : colors.primary,
              }}
            >
              {publicationSelect ? publicationIcon : <Visibility />}
            </InputAdornment>
          }
        >
          {oiraGroup.map((groupOira: IOiraForm) => (
            <MenuItem key={groupOira.uuid} value={groupOira.uuid}>
              {groupOira.title} (Version {groupOira.version} du{" "}
              {DateTime.fromISO(groupOira.creationDate).toLocaleString()})
            </MenuItem>
          ))}
          {publicationSelect && (
            <MenuItem key={unpublishedOira} value={unpublishedOira}>
              {publishedVersion === unpublishedOira ? "Aucune version publiée" : "Dépublier ce questionnaire"}
            </MenuItem>
          )}
        </Select>
      </FormControl>
    );
  };

  return (
    <Stack justifyContent="center" width="100%">
      <Header title={`Oira : ${oira.title} - Version ${oira.version}`}>
        <Button
          size="large"
          variant="contained"
          component="label"
          sx={{
            backgroundColor: colors.mainOrange,
            borderRadius: 3,
            width: 1,
          }}
          startIcon={<FontAwesomeIcon size="xl" style={{ marginRight: "1rem" }} icon={faUpload} />}
        >
          Importer une nouvelle version
          <input
            hidden
            accept=".xlsx, .xls, .csv"
            type="file"
            ref={hiddenFileInput}
            onChange={importOiraForm}
            onClick={(event) => {
              // This is to reset files, so we can try uploading again the same file
              const nativeEvent = event.nativeEvent.target as HTMLInputElement;
              nativeEvent.value = "";
            }}
          />
        </Button>
      </Header>
      {loading || oira.sections.length === 0 ? (
        <SkeletonOira />
      ) : (
        <Stack spacing={5} margin={5}>
          <Stack direction="row" spacing={10}>
            {renderSelection("Version publiée", true, changePublicationStatus)}
            {renderSelection("Version affichée", false, changeDisplayedVersion)}
          </Stack>
          {oira.sections.map((section) => (
            <OiraSection key={section.uuid} section={section} oiraUuid={oira.uuid} setOira={setOira} />
          ))}
          <Box />
        </Stack>
      )}
    </Stack>
  );
}
