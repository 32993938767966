import { IDuerpForm, IDuerpQuestion } from "../interfaces/DuerpForm";
import { AccompanyingStatus, ActionPriority, ProgressStatus, Type } from "../interfaces/Form";
import { IOiraForm } from "../interfaces/OiraForm";
import {
  IComplementaryAnswer,
  IComplementaryForm,
  IComplementarySection,
  ISectionEnum,
} from "../interfaces/ComplementaryForm";
import { ICompany } from "../interfaces/Company";
import { IOrganization } from "../interfaces/Organization";

// HEADERS
export const ApplicationTokenExpirationDate = "ApplicationTokenExpirationDate";
export const AmetraToken = "x-ametra-token";
export const AmetraTotalCount = "x-ametra-total-count";

// STRING FORMATTING
export const dateFormatRegexp = /^\d{2}\/\d{2}\/\d{4}$/;
export const dateFormatString = "dd/MM/yyyy";
export const generalRowsPerPageOptions = [10, 20, 50];
export const frenchPhoneFormatRegexp = /^[+0](?:\d\s?){8,12}$/gm;
// TEST REGEXP : kept just in case
///^((?:\+|00)[17](?: |\-)?|(?:\+|00)[1-9]\d{0,2}(?: |\-)?|(?:\+|00)1\-\d{3}(?: |\-)?)?(0\d|\([0-9]{3}\)|[1-9]{0,3})(?:((?: |\-)[0-9]{2}){4}|((?:[0-9]{2}){4})|((?: |\-)[0-9]{3}(?: |\-)[0-9]{4})|([0-9]{7}))/gm;
// "((?:+|00)[17](?: |-)?|(?:+|00)[1-9]d{0,2}(?: |-)?|(?:+|00)1-d{3}(?: |-)?)?(0d|([0-9]{3})|[1-9]{0,3})(?:((?: |-)[0-9]{2}){4}|((?:[0-9]{2}){4})|((?: |-)[0-9]{3}(?: |-)[0-9]{4})|([0-9]{7}))";
///^\+?(\s)?\d*\s\d{3}(\s|-)?\d{3}(\s|-)?\d*(\s|-)?\d*$/gm;
// /^(?:(?:(?:\+|00)33[ ]?(?:\(0\)[ ]?)?)|0){1}[1-9]{1}([ .-]?)(?:\d{2}\1?){3}\d{2}$/gm;

export const numberRegexp = /^\d*$/gm;

//
export const maxSPSTILines = 50;

// EMPTY VARIABLES
export const emptyDuerp: IDuerpForm = {
  accompanyingStatus: AccompanyingStatus.NOT_ACCOMPANIED,
  creationDate: "",
  documentType: Type.DUERP,
  generalComment: "",
  progressStatus: ProgressStatus.NOT_FINALIZED,
  reference: "",
  sections: [],
  title: "",
  uuid: "",
  companyUuid: "",
  oiraFormUuid: "",
  lastUpdatedDate: "",
  validationDate: null,
};

export const emptyQuestion: IDuerpQuestion = {
  actionPriority: ActionPriority.LOW,
  companyComment: "",
  currentMeasures: [],
  description: "",
  expertComment: "",
  futureMeasures: [],
  oiraQuestionUuid: "",
  riskManaged: false,
  title: "",
  uuid: "",
  completed: false,
  addByExpert: false,
};

export const emptyOira: IOiraForm = {
  creationDate: "",
  groupUuid: "",
  published: false,
  title: "",
  sections: [],
  uuid: "",
  version: 0,
};

export const emptyComplementaryForm: IComplementaryForm = {
  answersDictionary: {},
  duerps: [],
  creationDate: "",
  documentType: Type.COMPLEMENTARY_FORM,
  progressStatus: ProgressStatus.NOT_FINALIZED,
  accompanyingStatus: AccompanyingStatus.UNDEFINED,
  reference: "",
  uuid: "",
  lastUpdatedDate: "",
  validationDate: null,
};

export const emptyComplementaryAnswer: IComplementaryAnswer = {
  uuid: "",
  value: "",
};

export const endSection: IComplementarySection = {
  label: "Fin du questionnaire",
  enumValue: ISectionEnum.END,
  questions: [],
};

export const emptyCompany: ICompany = {
  documents: [],
  externalCollaborators: [],
  uuid: "",
  companyName: "",
  membershipNumber: "",
  membershipOrganization: "",
  collaborators: [],
};

export const emptyOrganization: IOrganization = {
  address: "",
  cguVersion: "",
  companyName: "",
  electronicAddress: "",
  firstname: "",
  lastname: "",
  rcsregistration: "",
  spstipresentation: "",
  uuid: "",
  organizationName: "",
  logoUuid: "",
};
