import { AxiosResponse } from "axios";
import HttpService from "./HttpService";
import { IShortForm, DocumentFormat } from "../interfaces/Form";
import { Endpoints } from "../resources/Endpoints";
import { IDuerpCreation, IDuerpForm } from "../interfaces/DuerpForm";

export default function DuerpService() {
  const getDuerpFormShorts = (uuid: string): Promise<AxiosResponse<IShortForm[]>> =>
    HttpService.fetch({
      url: Endpoints.companies.duerp(uuid),
      method: "get",
    });

  const archiveDuerp = (duerpUuid: string): Promise<AxiosResponse<IDuerpForm>> =>
    HttpService.fetch({
      url: Endpoints.duerpForms.archiveDuerp(duerpUuid),
      method: "delete",
    });

  const createDuerp = (duerpCreationDto: IDuerpCreation): Promise<AxiosResponse<IDuerpForm>> =>
    HttpService.fetch({
      url: Endpoints.duerpForms.base,
      method: "post",
      data: duerpCreationDto,
    });

  const updateDuerp = (duerpDto: IDuerpForm): Promise<AxiosResponse<IDuerpForm>> =>
    HttpService.fetch({
      url: Endpoints.duerpForms.getDuerp(duerpDto.uuid),
      method: "put",
      data: duerpDto,
    });

  const getDuerpById = (uuid: string): Promise<AxiosResponse<IDuerpForm>> =>
    HttpService.fetch({
      url: Endpoints.duerpForms.getDuerp(uuid),
      method: "get",
    });

  const getDuerpByReference = (reference: string): Promise<AxiosResponse<IDuerpForm>> =>
    HttpService.fetch({
      url: Endpoints.duerpForms.getDuerpByReference,
      method: "get",
      params: {
        duerpReference: reference,
      },
    });

  const duplicateDuerp = (duerpUuid: string, companyUuid: string): Promise<AxiosResponse<IDuerpForm>> =>
    HttpService.fetch({
      url: Endpoints.duerpForms.duplicateDuerp(duerpUuid),
      method: "post",
      data: companyUuid,
      headers: { "Content-Type": "application/json" },
    });

  const deleteDuerp = (duerpUuid: string): Promise<AxiosResponse<IDuerpForm>> =>
    HttpService.fetch({
      url: Endpoints.duerpForms.getDuerp(duerpUuid),
      method: "delete",
    });
  const downloadDuerp = (duerpUuid: string, format: DocumentFormat): Promise<AxiosResponse<Blob>> =>
    HttpService.fetch({
      url: Endpoints.duerpForms.exportDuerp(duerpUuid),
      method: "get",
      params: {
        format,
      },
      responseType: "blob",
    });

  const downloadActionPlan = (duerpUuid: string, format: DocumentFormat): Promise<AxiosResponse<Blob>> =>
    HttpService.fetch({
      url: Endpoints.duerpForms.actionPlanDuerp(duerpUuid),
      method: "get",
      params: {
        format,
      },
      responseType: "blob",
    });

  return {
    getDuerpFormShorts,
    downloadDuerp,
    archiveDuerp,
    deleteDuerp,
    duplicateDuerp,
    updateDuerp,
    createDuerp,
    getDuerpById,
    getDuerpByReference,
    downloadActionPlan,
  };
}
