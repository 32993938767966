import { AxiosResponse } from "axios";
import { toast } from "react-toastify";
import { ConflictException } from "../interfaces/Exception";


export const handleConflictOnDuerp = (res: AxiosResponse) => {
  const error = res.data as unknown as ConflictException;
  let toastMessage = "Votre version du DUERP n'est pas à jour. Dernière modification effectuée le " + error.lastUpdatedDate;
  console.log(!!error.lastUpdatedUser);
  if (error.lastUpdatedUser) toastMessage = toastMessage.concat(", par ", error.lastUpdatedUser);
  toast.error(toastMessage);
  toast.error("Veuillez rafraîchir la page et ne pas continuer l’édition des autres questions.");
};
