import { DateTime } from "luxon";
import { IShortForm, ActionPriority } from "./Form";

export interface IDuerpCreation {
  companyUuid: string;
  oiraFormUuid: string;
}

export interface IDuerpForm extends IShortForm {
  title: string;
  sections: IDuerpSection[];
  generalComment: string;
  companyUuid: string;
  oiraFormUuid: string;
}

export type IDuerpSection =
  | {
      uuid: string;
      oiraSectionUuid: string;
      title: string;
      description: string;
      optional: boolean;
      companyConcerned: boolean;
      customRisksSection: boolean;
      questions: IDuerpQuestion[];
      subSections?: never;
      completed: boolean;
    }
  | {
      uuid: string;
      oiraSectionUuid: string;
      title: string;
      description: string;
      optional: boolean;
      companyConcerned: boolean;
      customRisksSection: boolean;
      questions?: never;
      subSections: IDuerpSection[];
      completed: boolean;
    };

export interface IDuerpQuestion {
  uuid: string;
  title: string;
  oiraQuestionUuid: string;
  description: string;
  companyComment: string;
  expertComment: string;
  actionPriority: ActionPriority;
  riskManaged: boolean;
  currentMeasures: IDuerpAnswer[];
  futureMeasures: IDuerpAnswer[];
  completed: boolean;
  addByExpert: boolean;
}

export enum AnswerType {
  CURRENT = "CURRENT",
  FUTURE = "FUTURE",
}

export interface IDuerpAnswer {
  uuid: string;
  oiraAnswerUuid: string;
  answerType: AnswerType;
  duerpQuestionUuid: string;
  title: string;
  description?: string;
  date?: DateTime;
  expertComment: string;
  checkedByExpert: boolean;
  checkedByCompany: boolean;
  currentlyInPlace: boolean;
}
