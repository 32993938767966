import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Stack, Typography } from "@mui/material";
import { useAtom } from "jotai";
import { useEffect, useState } from "react";
import { FieldValues, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import * as yup from "yup";
import { currentDuerpAtom } from "../../../../../atoms/Atoms";
import { ICompanyShort } from "../../../../../interfaces/Company";
import { IDuerpForm } from "../../../../../interfaces/DuerpForm";
import { ProgressStatus, Type } from "../../../../../interfaces/Form";
import colors from "../../../../../resources/cssConstant";
import { StatusCode } from "../../../../../resources/StatusCode";
import CompanyService from "../../../../../services/CompanyService";
import DuerpService from "../../../../../services/DuerpService";
import { stringifyErrorMessage } from "../../../../../utils/ConversionMethods";
import { handleConflictOnDuerp } from "../../../../../utils/HandleToasterError";
import GenericButton from "../../../../Generics/buttons/GenericButton";
import GenericTextField from "../../../../Generics/GenericTextField/GenericTextField";
import DuplicateDocumentButton from "../../../DocumentActions/DuplicateDocumentButton/DuplicateDocumentButton";

const commentSchema = yup.object().shape({
  comment: yup.string().max(2000, "Votre commentaire ne doit pas excéder 2000 caractères.")
});

interface DuerpValidationPageProps {
  companyUuid: string;
  goToNextPage: () => void;
}

export default function DuerpValidationPage({ companyUuid, goToNextPage }: Readonly<DuerpValidationPageProps>) {
  const [duerp, setDuerp] = useAtom(currentDuerpAtom);
  const [companyShort, setCompanyShort] = useState<ICompanyShort>({
    collaborators: [],
    companyName: "",
    complementaryFormsNumber: 0,
    duerpsNumber: 0,
    lastComplementaryForm: null,
    lastDuerp: null,
    externalCollaborators: [],
    logoUuid: "",
    membershipNumber: "",
    membershipOrganization: "",
    uuid: ""
  });
  const {
    register: registerComment,
    handleSubmit: handleSubmitComment,
    formState: { errors: errorsComment }
  } = useForm({
    resolver: yupResolver(commentSchema),
    mode: "onTouched"
  });

  const fetchCompanyShort = async () => {
    const res = await CompanyService().getCompanyShortById(companyUuid);
    if (res.data) {
      setCompanyShort(res.data);
    }
  };

  useEffect(() => {
    fetchCompanyShort();
  }, []);

  const updateDuerp = async (updatedDuerp: IDuerpForm, isValidate: boolean) => {
    const res = await DuerpService().updateDuerp(updatedDuerp);
    if (res.status === StatusCode.OK && isValidate) {
      toast.success("Votre DUERP a été validé.");
      goToNextPage();
      setDuerp(res.data);
    } else if (res.status === StatusCode.OK) {
      toast.success("Votre commentaire a été enregistré.");
      setDuerp(res.data);
    } else if (res.status === StatusCode.CONFLICT) {
      handleConflictOnDuerp(res);
    } else {
      toast.error("Impossible de valider ce DUERP pour le moment.");
    }
  };
  const validateDuerp = async () => {
    const updatedDuerp = { ...duerp };
    updatedDuerp.progressStatus = ProgressStatus.FINALIZED;
    await updateDuerp(updatedDuerp, true);
  };

  const addComment = async (data: FieldValues) => {
    const updatedDuerp = { ...duerp };
    updatedDuerp.generalComment = data.comment;
    await updateDuerp(updatedDuerp, false);
  };

  const renderOnGoingDuerp = () => (
    <Stack spacing={5}>
      <Typography variant="body1">
        {`Vous êtes arrivé à la fin de ce questionnaire ! Tant que vous ne l'avez pas validé, vous pouvez poursuivre vos
          modifications.`}
      </Typography>
      <Stack spacing={2} sx={{ mb: 5 }}>
        <Typography variant="body1">
          {`Si vous avez fini d'identifier tous les risques dans votre organisation et sélectionné les mesures à mettre en
            œuvre, vous pouvez valider ce document unique. Un rapport téléchargeable, répertoriant vos résultats ainsi
            qu’un plan d’action sera édité.`}
        </Typography>
        <Typography variant="body1" gutterBottom>
          Vous pouvez, si vous le souhaitez, ajouter ci-dessous des commentaires supplémentaires qui seront inclus dans
          ce rapport.
        </Typography>
        <Stack component="form" spacing={2} alignItems="flex-start">
          <GenericTextField
            id="outlined-multiline-general-comment"
            label="Commentaire général"
            multiline
            rows={4}
            defaultValue={duerp.generalComment}
            placeholder="Ajoutez un commentaire global de détails à apporter au DUERP"
            register={registerComment("comment")}
            error={!!errorsComment.comment}
            helperText={stringifyErrorMessage(errorsComment.comment)}
          />
          <GenericButton onClick={handleSubmitComment(addComment)} text="Enregistrer le commentaire général" />
        </Stack>
      </Stack>
      <Stack spacing={2} alignItems="flex-start">
        <Typography variant="body2">Attention :</Typography>
        <Typography variant="body1" gutterBottom>
          En validant ce DU, vous ne pourrez plus modifier vos réponses. Cependant, vous pourrez utiliser les réponses
          du DU validé en démarrant une nouvelle évaluation.
        </Typography>
        <GenericButton onClick={validateDuerp} text="Valider ce Duerp" icon={faCheck} color={colors.primary} />
      </Stack>
    </Stack>
  );

  const renderFinalizedDuerp = () => (
    <Stack spacing={5}>
      <Typography variant="body1">Vous êtes arrivé à la fin de ce questionnaire !</Typography>
      <Typography variant="body1">
        Ce questionnaire a été validé. Vous ne pouvez plus effectuer de modification.
      </Typography>
      <Box>
        <Typography variant="body1" gutterBottom>
          Vous pouvez, si vous le souhaitez, utiliser les réponses de ce questionnaire pour commencer un nouveau DUERP.
        </Typography>
        <DuplicateDocumentButton
          company={companyShort}
          documentType={Type.DUERP}
          documentUuid={duerp.uuid}
          iconButton={false}
        />
      </Box>
    </Stack>
  );

  return (
    <Stack spacing={5} mb={10} width="60%">
      <Typography variant="h3">Fin du questionnaire</Typography>
      {duerp.progressStatus === ProgressStatus.NOT_FINALIZED ? renderOnGoingDuerp() : renderFinalizedDuerp()}
    </Stack>
  );
}
